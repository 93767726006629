<template>
    <div class="qingwu">
        <div class="admin_main_block">
            <div class="admin_breadcrumb">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item :to="{ path: '/Admin/index' }">首页</el-breadcrumb-item>
                    <el-breadcrumb-item>任务管理</el-breadcrumb-item>
                    <el-breadcrumb-item>审核列表</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
        </div>
        <div class="admin_main_block top">
            <el-row class="total-header">
                <el-col :span="span4" class="span4">
                    <el-row>
                        <el-col :span="span12">总完成任务数:</el-col>
                        <el-col :span="span10"><span>{{total.completeCount}}</span></el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="span12">今日已审核任务数:</el-col>
                        <el-col :span="span10"><span>{{total.todayReviewCount}}</span></el-col>
                    </el-row>
                </el-col>
                <el-col :span="span4" class="span4">
                    <el-row>
                        <el-col :span="span12">待审核任务数:</el-col>
                        <el-col :span="span10"><span>{{total.pendReviewCount}}</span></el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="span12">未通过任务数:</el-col>
                        <el-col :span="span10"><span>{{total.refuseCount}}</span></el-col>
                    </el-row>
                </el-col>
            </el-row>
        </div>
        <div class="admin_main_block admin_m15">
            <div class="header-form">
                <div class="admin_main_block_left">
                    <el-form>
                        <el-form-item label="起止日期">
                            <el-date-picker
                                    v-model="date"
                                    @change="changeDate" @input="immediUpdate"
                                    type="daterange"
                                    range-separator="~"
                                    value-format="yyyy-MM-dd"
                                    format="yyyy-MM-dd"
                                    start-placeholder="开始日期"
                                    end-placeholder="截止日期"
                                    :clearable="true"
                                    unlink-panels
                            >
                                <i class="el-icon-date"></i>
                            </el-date-picker>
                        </el-form-item>
                        <el-form-item label="状态">
                            <el-select v-model="where.status" placeholder="状态">
                                <el-option label="全部" value=""></el-option>
                                <el-option label="待审核" value="10"></el-option>
                                <el-option label="审核通过" value="20"></el-option>
                                <el-option label="审核拒绝" value="30"></el-option>
                                <el-option label="进行中" value="0"></el-option>
                                <el-option label="已放弃" value="1"></el-option>
                                <el-option label="已过期" value="2"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="关键字">
                            <el-input v-model="where.keyword" placeholder="任务名称/手机号/领奖资料" clearable></el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" plain icon="el-icon-search" @click="listData()">条件筛选</el-button>
                        </el-form-item>
                    </el-form>
                </div>

                <div class="admin_main_block_right">
                    <el-row :gutter="20">
                        <el-col :span="12">

                        </el-col>
                        <el-col :span="12">
                            <el-button
                                    type="primary"
                                    @click="exportData"
                                    disabled
                                    icon="el-icon-tickets">导出数据
                            </el-button>
                        </el-col>
                    </el-row>
                </div>
            </div>
            <div class="admin_table_main">
                <el-table
                        :data="list"
                        v-loading="loading"
                        element-loading-text="拼命加载中"
                        element-loading-spinner="el-icon-loading"
                        element-loading-background="rgba(0, 0, 0, 0.1)"
                >
                    <el-table-column prop="id" label="ID"></el-table-column>
                    <el-table-column prop="createTime" label="时间"></el-table-column>
                    <el-table-column prop="userId" label="用户ID"></el-table-column>
                    <el-table-column prop="mobile" label="用户手机号"></el-table-column>
                    <el-table-column prop="taskName" label="任务名称"></el-table-column>
                    <el-table-column prop="money" label="任务单价"></el-table-column>
                    <el-table-column prop="receiveData" label="领奖资料">
                        <template slot-scope="scope">
                            <p v-for="(item,index) in scope.row.receiveData" :key="item">{{index}}:{{item}}</p>
                        </template>
                    </el-table-column>
                    <el-table-column prop="picList" label="上传照片">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" content="点击浏览图片列表" placement="top-start">
                            <el-image v-if="scope.row.picList.length>0"
                                      class="showImg"
                                      :src="scope.row.picList[0]"
                                      fit="scale-down"
                                      :preview-src-list="scope.row.picList"
                            >
                                <div slot="error" class="image-slot">
                                    <i class="el-icon-picture-outline"></i>
                                </div>
                            </el-image>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column prop="status" label="当前状态">
                        <template slot-scope="scope">
                            <span :class="'status'+scope.row.status">{{ scope.row.statusTxt }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" fixed="right">
                        <template slot-scope="scope">
                            <el-button
                                    type="text"
                                    :class="getStyle(scope.row,'class')"
                                    size="medium"
                                    @click="add(scope.row)">{{getStyle(scope.row,'text')}}
                            </el-button>
                            <el-button
                                    v-if="scope.row.status === 30 && scope.row.isGoback === 0"
                                    type="text"
                                    class="el-icon-money"
                                    size="medium"
                                    @click="rollback(scope.row)">退贡献值
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="admin_table_main_pagination">
                    <el-pagination
                            @current-change="currentChange"
                            @size-change="handleSizeChange"
                            background
                            :total="totalData"
                            :page-size="pageSize"
                            :current-page="currentPage"
                            :page-sizes="[10, 20, 50, 100]"
                            layout="sizes, prev, pager, next, total, jumper"
                    ></el-pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        components: {},
        props: {
            state: Number,
        },
        data() {
            return {
                span: 6,
                span4: 5,
                span12: 12,
                span10: 10,
                list: [],
                date: "",
                row: {},
                loading: false,
                dialogDetailVisible: false,
                dialogEditVisible: false,
                totalData: 0, // 总条数
                pageSize: 20,
                currentPage: 0,
                selectId: "",
                where: {
                    pageNum: 0,//分页，页数
                    pageSize: 0,//分页，每页大小
                    status:'',   // 状态【0-提交进行中，10-审核中，20-审核通过，30-审核拒绝】
                    keyword: '',
                    startDt: '',    // 开始日期，格式【yyyy-MM-dd】
                    endDt: ''    // 结束日期，格式【yyyy-MM-dd】
                },
                total: {
                    completeCount:0,  // 总完成任务数
                    todayReviewCount:0, // 今日已审核任务数
                    pendReviewCount:0, // 待审核任务数
                    refuseCount:0,   // 未通过任务数
                },

            };
        },
        methods: {
            listData() {
                this.loading = true;
                this.where.pageNum = this.currentPage;
                this.where.pageSize = this.pageSize;
                this.$post(this.$api.taskUserHeadList, this.where).then((res) => {
                    this.totalData = res.data.total;
                    this.list = res.data.records;
                    this.loading = false;
                    // this.getTotal();
                });
            },
            handleSelectionChange(e) {
                let ids = [];
                e.forEach((v) => {
                    ids.push(v.id);
                });
                this.selectId = ids.join(",");
            },
            currentChange(e) {
                this.currentPage = e;
                this.listData();
            },
            handleSizeChange(e) {
                this.pageSize = e;
                this.listData();
            },
            changeDate(obj) {
                if (obj) {
                    this.where.startDt = obj[0];
                    this.where.endDt = obj[1];
                }
            },
            immediUpdate(e) {
                this.$nextTick(() => {
                    console.log(e)
                    if(e==null){
                        this.where.startDt = '';
                        this.where.endDt = '';
                    }
                });
            },
            exportData() {
                console.log("exportData");
                this.$get(this.$api.taskUserListExport,this.where).then((res) => {
                    if (res.code === 1) {
                        location.href = res.data;
                    }
                });
            },
            getTotal() {
                this.$post(this.$api.taskUserHeadStatistics,{}).then((res) => {
                    if (res.code === 1) {
                        this.total = res.data;
                    }
                });
            },
            add(param) {
                let obj = {};
                if(param){
                    obj = {id:param.id};
                }
                this.$router.push({name: "auditView",query:obj});
            },
            getStyle({status},type){
                if(type === 'class'){
                    if(this.isInArray([10],status) !== -1){
                        return "el-icon-edit";
                    }else{
                        return "el-icon-document";
                    }
                }
                if(type === 'text'){
                    if(this.isInArray([10],status) !== -1){
                        return "修改";
                    }else{
                        return "查看";
                    }
                }
            },
            isInArray(array, str) {
                return array.findIndex(item => item === str);// -1 说明array中不存在id为str的对象
            },
            rollback({id}){
                console.log(id);
                this.$confirm("回退贡献值?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                })
                    .then(() => {
                        this.$get(this.$api.taskUserBackGxz+id,{}).then((res) => {
                            if (res.code === 1) {
                                this.$message({
                                    type: "success",
                                    message: "回退成功",
                                });
                                this.listData();
                            }else{
                                this.$message({
                                    type: "error",
                                    message: res.msg,
                                });
                            }
                        });
                    })
                    .catch(() => {
                        this.$message({
                            type: "info",
                            message: "已取消",
                        });
                    });
            },
        },
        created() {
            this.listData();
            this.getTotal();
        },
    };
</script>
<style lang="scss" scoped>
    .total-header {
        margin-bottom: 0 !important;
        margin-top: 15px;

        .el-col {
            margin-right: 15px;

            :last-child {
                margin-right: 0px;
            }
        }
    }

    .el-row {
        margin-bottom: 30px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .el-col {
        border-radius: 4px;
    }

    .header-form {
        height: 40px;
        padding: 10px 0 10px 0;
    }

    .span4 {
        background: rgb(217, 236, 255);
        font-size: 16px;
        padding: 30px 0;
        text-align: center;
        vertical-align: center;

        span {
            font-size: 18px;
        }
    }

    .endDate {
        color: #c0c4cc;
    }

    .el-select {
        width: 120px;
    }
    .status0 {
        color: #E6A23C;
    }

    .status20 {
        color: #67C23A;
    }

    .status10 {
        color: #F56C6C;
    }

    .status30 {
        color: #909399;
    }
    .status1 {
        color: #ecf5ff;
    }
    .status2 {
        color: #99a9bf;
    }
    .showImg{
        width: 50px; height: 50px; cursor: pointer;
    }
</style>
